import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../styles/style.css"

const EmpleoForm = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(
        relativeDirectory: { eq: "empleo" }
        name: { eq: "cabecera-empleo" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Perfil Diseñador de dispositivos electrónicos | Qualoom</title>
        <meta name="title" content="Perfil Diseñador de dispositivos electrónicos | Qualoom"/>
        <meta name="description" content="Qualoom busca un perfil con experiencia en el diseño, implementación y puesta a punto de sistemas electrónicos con hardware analógico/digital microcontrolado."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/perfil-disenador-dispositivos-electronicos/"/>
        <link rel="canonical" href="https://www.qualoom.es/perfil-disenador-dispositivos-electronicos/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/perfil-disenador-dispositivos-electronicos/"/>
        <meta property="og:title" content="Perfil Diseñador de dispositivos electrónicos | Qualoom"/>
        <meta property="og:description" content="Qualoom busca un perfil con experiencia en el diseño, implementación y puesta a punto de sistemas electrónicos con hardware analógico/digital microcontrolado."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/2RlgyCrAEUpdKRxj8UWjUG/f51308c39df9bfd9f8aeabe572a9cd62/tw_cards_empleo.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/perfil-disenador-dispositivos-electronicos/"/>
        <meta property="twitter:title" content="Perfil Diseñador de dispositivos electrónicos | Qualoom"/>
        <meta property="twitter:description" content="Qualoom busca un perfil con experiencia en el diseño, implementación y puesta a punto de sistemas electrónicos con hardware analógico/digital microcontrolado."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/2RlgyCrAEUpdKRxj8UWjUG/f51308c39df9bfd9f8aeabe572a9cd62/tw_cards_empleo.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

      </Helmet>{" "}
      <BackgroundImage fluid={data.image1.childImageSharp.fluid}>
        <div className="background-empleo">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Perfil Diseñador de dispositivos electrónicos</h1>
              <p>Qualoom busca incorporar a su equipo un perfil con experiencia en el diseño, implementación y puesta a punto de sistemas electrónicos con hardware analógico/digital microcontrolado. Preferiblemente con residencia Móstoles o alrededores.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="job">
        <h3>Conocimientos</h3>
        <ol>
          <li>Análisis, cálculo y diseño de sistemas electrónicos. Estrategias de diseño para bajo consumo, comunicaciones inalámbricas y diseño robusto.</li>
          <li>Microcontroladores, control de alimentación, adquisición de datos de sensores, sistemas de comunicación inalámbrica, selección de componentes.</li>
          <li>Análisis, implementación y debug de SW en microcontroladores. Arquitectura ARM. PSoC (Programmable System on Chip).</li>
          <li>Diseño de circuitos impresos para fabricación. Circuitos de doble capa, DRC, Gerber.</li>
          <li><b>Imprescindible</b> dominio de C y C++.</li>
          <li><b>Imprescindible</b> conocimientos en la generación de esquemáticos y rutado de PCBs con Altium, KiCad y/o Eagle.</li>
          <li><b>Imprescindible</b> conocimientos en el desarrollo de firmware para sistemas embebidos.</li>
        </ol>
        <h3>Otros conocimientos valorados</h3>
        <ol>
          <li>Experiencia previa en protocolos de comunicación CAN, Doors o Reqtify, Vector, V-Model.</li>
          <li>Experiencia con sistemas operativos como Freertos y Linux.</li>
          <li>Experiencia previa en electrónica industrial, robótica o similares.</li>
          <li>Valorable un buen nivel de inglés</li>
        </ol>
        <h3>Requisitos del perfil</h3>
        <ol>
          <p>El candidato tendrá que disponer de alguna de estas titulaciones y asimiladas o experiencia demostrable que acredite su capacidad en este ámbito.</p>
          <li>Técnico Superior en Desarrollo de Productos Electrónicos</li>
          <li>Técnico Superior en Mantenimiento Electrónico</li>
          <li>Ingeniero Técnico Industrial especialidad Electrónica</li>
          <li>Grado en Ingeniería Electrónica</li>
          <li>Grado en Ingeniería Electrónica Industrial</li>
          <li>Ingeniero Industrial Electrónica y Automática</li>
        </ol>
        <h3>Te ofrecemos</h3>
        <ol>
          <li>El reto de participar en proyectos innovadores.</li>
          <li>Colaborar con equipos multidisciplinares obteniendo una visión 360º de la compañía y clientes</li>
          <li>Certificaciones y formación</li>
          <li>Trabajo con grandes profesionales y en equipos multidisciplinares.</li>
          <li>Plan de carrera adecuado a tus necesidades.</li>
          <li>Contrato por obra y servicio hasta el 01/01/2021 con alta probabilidad de renovación tras 5 años con este cliente</li>
          <li>Algo que seguro no podrás rechazar. Trabajar en la zona sur de Madrid (Móstoles), con las ventajas que esto conlleva, posibilidad de ir a comer casa, tiempo de transporte al trabajo apenas existente.</li>
          <li>Remuneración: Según experiencia y conocimietos aportados</li>
        </ol>
        <a href="mailto:empleo@qualoom.es ?subject=Candidatura Perfil Administración de sistemas Línux y BigData &body=Hola, %0A%0A Estoy interesado en formar parte del equipo de Qualoom. %0A%0A Les remito los siguientes datos y adjunto mi CV para mi candidatura: %0A%0A%09 Nombre: %0A%0A%09 Apellidos: %0A%0A%09 Contacto: %0A%0A%0A Saludos." className="button-job">Envíanos tu CV para participar en esta oportunidad</a>
      </div>
      <Footer />
    </div>
  )
}

export default EmpleoForm
